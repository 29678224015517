@import '../variables';

.footer--endC {
    border-radius: 5px 5px 0 0 !important;
}

.cart--summary-header-cuerpo img {
    vertical-align: middle;
}
.cartElementContract{
    box-shadow: 1px 1px 10px 1px rgba(0,0,0,0.25);
}
.cart--summary {
    border-radius: 5px;

}
.headerContract {
    border-radius: 5px 5px 0 0;
}

.cart--summary-header-cuerpo {
    width: 100%;
    padding: 1em;
    box-sizing: border-box;
    background-color: #f2f2f2;
    border-bottom: 1px solid #ddd;
    border-radius: 0 0 5px 5px;
}

//------
h3 {
    font-size: 1.70rem;
    //font-family: 'roboto-medium' !important;
}

.header-claro {
    display: flex;
    justify-content: center;
    align-items: center;
}

.col-sm-6 {
    flex: 0 0 auto;
    width: 45%;
}

.row>* {
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x)/ 2);
    padding-left: calc(var(--bs-gutter-x)/ 2);
    margin-top: var(--bs-gutter-y);
}
.pb-3 {
    padding-bottom: 1rem!important;
    padding-right: 1rem !important;
    border-radius: 2px;
}
.align-buttom {
    text-align: right !important;
    flex: 0 0 auto;
    width: 100%;
    float: right;

}
.align-form {
    /*padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;*/
   display: flex;
    flex-wrap: wrap;

}

.format-form {
    padding-right: 1.5rem!important;
    padding-left: 1.5rem!important;
}

.checkout--info {
    width: 59% !important;
}

.format-select {
    display: block;
    width: 100%;
    padding: .375rem 2.25rem .375rem .75rem !important;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529 !important;
    background-color: #fff !important;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right .75rem center;
    background-size: 16px 12px;
    border: 1px solid #ced4da !important;
    border-radius: .25rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

}


/*.checkout--info {
    margin-top: 1rem!important;
    margin-bottom: 1rem!important;
    flex: 0 0 auto;
    width: 100%;

    //display: block;
    min-height: 1.5rem;
    padding-left: 1.5em;
    margin-bottom: .125rem;
}*/

/*aside.checkout--continue {
    justify-content: flex-end !important;
}*/
.footer--endC section {
    justify-content: center;
}

.check-form {
    border: 1px solid #da291c;
    //position: relative;
    max-width: 150px;//160
    border-radius: 5px;
    height: 100px;
    font-size: 0.6rem; //0.6
    width: 90%; //100%
    box-shadow: 3px 3px 10px 0px rgba(0,0,0,0.15);
}

ul.ul-pdf li {
    display:inline-block;
    display: flex;
    justify-content: center;
}

ul.ul-pdf li, ul.ul-pdf ol, ul.ul-pdf ul {
    list-style-type: none;
    display: flex;
    justify-content: center;
    align-items: center;
}
.red-text {
    color: #da291c !important;
    font-weight: 600;
    font-size: 1.5rem;

}
//-- texto checkout
.checkout--info-comply {
    margin: .5rem 0 1rem;
    font-size: .85rem;
}

.modal--content-validate-number header p {
    text-align: center;
    font-size: 1em;
    color: #000;
}

#contract-modal .modal-content {
    //background-color: #F2F2F2;
    border: 1px solid #F2F2F2;
    border-radius: 10px;
}
.inputTokenValido{
    display: block !important;
    margin: 1.5em auto !important;
    border: none !important;
    padding: 0 !important;
    width: 10.5ch !important;
    background: repeating-linear-gradient(90deg,dimgrey,dimgrey 1ch,#0000 0,#0000 1.75ch) 0 100%/10.5ch 2px no-repeat;
    font: 5ch droid sans mono,consolas,monospace !important ;
    letter-spacing: .75ch !important;
}



.rows {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    flex-wrap: wrap;
    //justify-content: center;
    align-content: center;
    display: flex;
}
.row2 {
    align-items: center;
    display: flex;
   // justify-content: center;
   // width: 10rem;
   text-align: center;
}
.row3 {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    flex-wrap: wrap;
   // justify-content: center;
    align-content: center;
    display: flex;
    height: 4px;
    padding-bottom: 1.5rem;
}

.row {
    display: flex;
    flex-wrap: wrap;
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    //justify-content: center;
    align-items: center;
}

.modal-footer {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.red-claro-btn {
    background: #da291c;
    color: white;
    font-family: $font-medium;
    font-size: 1rem;
    font-weight: 100;
    letter-spacing: -1px;
    line-height: 1.5rem;
    padding: 0.5rem 2rem;
}
.btn {
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    color: #888;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    /* -webkit-user-select: none; */
    -moz-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: .375rem .75rem;
    font-size: 1rem;
    border-radius: .25rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.align-button {
    display: flex;
    justify-content: flex-end;
    margin: .5rem 0 1rem 1rem;
}
.modal-header {
    color: #da291c;
    font-size: 1.8rem;
    font-weight: 900;
}

/*

.form-check-info {
    width: 1em;
    height: 1em;
    margin-top: .25em;
}

.checkout--info {
    margin: .5rem 0 0 !important;
    font-size: .85rem;
}

.form-check-info {
    width: 1em;
    height: 1em;
    margin-top: .25em;
}



.buttom-contract {
    display:flex;

}*/
