.input--container {
  display: grid;
  grid-auto-rows: auto;
  row-gap: 4px;

  > input[type="number"]::-webkit-inner-spin-button,
  > input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  > input[type="number"] {
    -moz-appearance: textfield;
    appearance: textfield;
  }

  // :read-only
  > input:read-only {
    background-color: #f5f5f5;
    color: #757575;
  }

}

.input--label {
  font-weight: bold;
  color: #424242;
  font-size: 16px;
}

.input--required {

}

.input--entry {
  border-radius: 12px !important;
  border-color: #bbbbbb !important;

  padding: 0.6em !important;
  box-sizing: border-box !important;
  display: inline-block !important;
  border: 1px solid #DDDDDD !important;
  width: 100% !important;
}
.input--error {
  color: #da291c;
  font-size: 12px;
  font-weight: 500;
} 