@import '../variables.scss';

.auth-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  opacity: 1;
  animation: showSlow 0.3s ease-in-out forwards;
}

@keyframes showSlow {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.auth-modal-content {
  background-color: #0097a9;
  padding: 20px;
  border-radius: 5px;
  position: relative;
  padding: 32px 36px;
  max-width: 480px;
  border-radius: 28px;
  width: calc(100vw - 12px);
  box-sizing: border-box;
}

.auth-modal-content-card {
  background: #fff;
  border-radius: 16px;
  padding: 24px 32px;
}

.auth-modal-content-card--signup {
  background: #fff;
  border-radius: 16px;
  padding: 24px 24px;
}

.auth-modal-close {
  position: absolute;
  top: 24px;
  right: 24px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 18px;
  color: #ffffff;
}

.auth-modal-close:hover {
  color: #ff0000;
}

.auth-modal-content-body {
}


.auth-modal-actions-buttons {
  display: grid;
  grid-template-columns: 1fr;
  justify-content: flex-end;
  column-gap: 12px;
  margin: 24px 0 18px 0;
}

.auth-header {
  display: grid;
  grid-auto-rows: auto;
  row-gap: 12px;
  margin-bottom: 8px;
  &--title {
    color: #fff;
    text-align: center;
    font-size: 32px;
    margin: 0 10px;
  }
  &--divider-yellow {
    height: 8px;
    width: 100%;
    border-radius: 24px;
    background: #ffc721;
  }
  &--description {
    color: #fff;
    text-align: center;
    font-size: 16px;
    margin: 12px 0;
  }
}

.login-form {
  display: grid;
  grid-auto-rows: auto;
  row-gap: 2px;
}

.password-recover {
  display: flex;
  justify-content: flex-end;
  margin: 12px 0;
  &--text {
    color: #424242 !important;
    text-decoration-color: #939393 !important;
    font-size: 14px;
    text-decoration: underline;
    cursor: pointer;
  }
}

.create-acount {
  margin: 24px 0 18px;
  width: 100%;
  &--text {
    text-align: center;
    width: 100%;
    color: $zblack !important;
    font-weight: 500;
    font-size: 14px;

  }
}

.password-requirements-container {
  display: grid;
  grid-auto-rows: auto;
  row-gap: 8px;
  margin: 16px 0;
  &--title {
    font-size: 14px;
    font-weight: 500;
    color: $font-color;
  }
  &--subtitle {
    font-size: 12px;
    font-weight: 400;
    color: $font-color;
  }
  &--list {
    list-style-type: circle !important;
    font-weight: 400;
    &--item {
      display: grid;
      grid-template-columns: max-content 1fr;
      align-items: center;
      column-gap: 6px;
      padding-left: 4px;
      &--dot {
        background: $blue;
        width: 8px;
        height: 8px;
        border-radius: 200px;
      }
      &--text {
        font-size: 12px;
      }
    }
  }
  
}

.go-to-signin {
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 12px;
  &--link {
    color: $blue;
    font-weight: 500;
    text-decoration: underline;
    cursor: pointer;
    padding-left: 4px;
  }
}


@media (max-width: 700px) {
  .auth-modal-overlay {
    display: block;
    bottom: 0;
    overflow: scroll;
  }
  .auth-modal-content {
    margin: 32px auto;
    width: calc(100vw - 24px);
    padding: 32px 24px;
    z-index: 1060;
  }

  .auth-header {
    &--title {
      text-align: left;
      font-size: 24px;
    }
  }

}

@media (max-width: 700px) {
  .auth-modal-overlay {
    display: block;
    bottom: 0;
    overflow: scroll;
  }
  .auth-modal-content {
    margin: 32px auto;
    width: calc(100vw - 24px);
    padding: 32px 24px;
    z-index: 1060;
  }

  .auth-header {
    &--title {
      text-align: left;
      font-size: 24px;
    }
  }

}

@media (max-height: 1200px) {
  .auth-modal-overlay {
    display: block;
    bottom: 0;
    overflow-y: scroll;
    body {
      overflow: hidden;
    }
  }
  .auth-modal-content {
    margin: 32px auto;
    width: calc(100vw - 24px);
    padding: 32px 24px;
    z-index: 1060;
  }

  .auth-header {
    &--title {
      text-align: left;
      font-size: 24px;
    }
  }

}

.reset-password {
  font-weight: 500;
  cursor: pointer;
}