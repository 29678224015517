@import '../variables.scss';

.select-picker-wrapper {
  display: grid;
  grid-auto-rows: auto;
  row-gap: 6px;
  &--label {
    font-weight: 500;
    color: $zblack;
  }
}

.select-picker {
  > select {
    border-radius: 10px !important;
    color: $zblack;
    font-weight: 400;
  }
}

.select-picker::after {
  border-left: none !important;
  content: "\f078";
  font-family: "FontAwesome";
  color: $zblack;
  font-size: 16px;
  display: flex;
  top: 6px;
  right: 6px;
}