@import '../variables';

:root {
  --select-border: #0097a9;
  --select-arrow: var(--select-border);
}

.generic {
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  *:focus {
    outline: 0;
  }

  .h3-light { font-family: $font-light; font-size: 2.2rem; color: red; }
  .roboto-bold{ font-family: $font-bold; }

  .mt-4 { margin-top: 4rem; }

  .vertical-center { display: flex; }
  .vertical-center header { margin: auto; }

  .product-row {
    display: flex;
    width: 100%;
    //flex-wrap: wrap;
    section {
      flex: auto;
      width: auto;
    }
  }

  .product--description { padding-top: 0; }

  .product-selections { font-family: $font-bold; font-size: 1rem; }

  @media screen and (min-width: 1024px) {
    .product--description .module {
      padding: 2.5em 1rem;
    }
  }

  @media screen and (max-width: 759px) {
    .product-slider-nav img {
      width: auto !important;
    }
    .product-row {
      display: block;
    }
  }

  .product--color div label i { line-height: 1.7; width: 24px; height: 24px; }

  .product-desc {
    font-size: 1rem;
    color: #767676;
    width: 100%;
    text-align: justify;
    padding-bottom: 1rem;
    margin-bottom: 0;
  }

  .product--datasheet section .module header { width: calc(100% - 90px); }
  .product--datasheet section .module div { border-bottom:1px solid $light; padding: .1em 0; }
  .no-border { border-bottom: 0 !important; }

  /* Custom select */
  select {
    appearance: none;
    background-color: transparent;
    border: none;
    padding: 0 1em 0 0;
    margin: 0;
    width: 100%;
    font-family: inherit;
    font-size: inherit;
    cursor: inherit;
    line-height: inherit;
    outline: none;
  }
  select::-ms-expand {
    display: none;
  }
  select, .custom-select:after {
    grid-area: select;
  }
  .custom-select {
    width: 80% !important;
    min-width: 15ch;
    max-width: 30ch;
    border: 2px solid var(--select-border);
    padding: 0.25em 0.5em;
    cursor: pointer;
    background-color: #fff;
    background-image: linear-gradient(to top, #f9f9f9, #fff 33%);
    display: grid;
    grid-template-areas: "select";
    align-items: center;
    position: relative;
    text-align: center;
    text-align-last: center;
  }
  .custom-select::after {
    content: "";
    width: 0.8em;
    height: 0.5em;
    background-color: var(--select-arrow);
    clip-path: polygon(100% 0%, 0 0%, 50% 100%);
    justify-self: end;
  }

  .product--specs article { border: none !important; width: 33.33% !important;white-space: pre-line; }
  .product--specs article p { white-space: pre-line; }
  .product--specs article img { width: 100px; height: 66px; margin: 0 auto; }

  @media screen and (max-width: 759px) {
    .product--specs article { width: 100% !important; }
  }

  .product--screen {margin-bottom:1.5em;width:100%}
  .product--screen p{font-weight:400;margin-bottom:.5em}
  .product--screen p:before{content:"4";display:inline-block;width:20px;height:20px;margin-right:.5em;color:#da291c;border:1px solid #ddd;text-align:center;-moz-border-radius:99px;-webkit-border-radius:99px;border-radius:99px}
  .product--screen div{width:100%}

  .product--cant {margin-bottom:1.5em;width:100%}
  .product--cant p{font-weight:400;margin-bottom:.5em}
  .product--cant p:before{content:"2";display:inline-block;width:20px;height:20px;margin-right:.5em;color:#da291c;border:1px solid #ddd;text-align:center;-moz-border-radius:99px;-webkit-border-radius:99px;border-radius:99px}
  .product--cant div{width:100%}
  .product--cant div input {
    border-radius: 20px;
    box-shadow: 		5px 5px 11px 0px rgba(0,0,0,0.20);
    -webkit-box-shadow: 5px 5px 11px 0px rgba(0,0,0,0.20);
    -moz-box-shadow: 	5px 5px 11px 0px rgba(0,0,0,0.20);
    padding-left: 1rem;
    width: 80%;
    text-align: center;
  }

  .product-row section:nth-child(2) p:before { content:"2"; }
  .product-row section:nth-child(3) p:before { content:"3"; }
  .product-row section:nth-child(4) p:before { content:"4"; }

  /* Tab Styles */
  .tab {
    display: none;
    padding: 6px 12px;
    border: 1px solid #ccc;
    border-top: none;
  }

}
