.Button {
  border-radius: 32px !important;
  border: none;
  color: #fff;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  cursor: pointer !important;
  font-family: 'Roboto', sans-serif !important;
  font-weight: 500 !important;
  -webkit-font-smoothing: antialiased;
  transition: transform 0.2s;
  outline: none;
  font-weight: 400;

}

.Button:active {
  transform: scale(0.98) translate3d(0,0,0);;
}

.Button:disabled {
  opacity: 0.5;
  cursor: not-allowed !important;
  background: gray !important;
  color: white;
  transform: scale(1);
}

.Button:hover {
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.18);
}
