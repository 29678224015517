.app-form-control {
  display: grid;
  grid-template-columns: 1em auto;
  gap: 0.5em;
  align-items: center;
}

.app-form-control {
  > input[type="checkbox"] {
    -webkit-appearance: none;
    appearance: none;
    background-color: #0097a9;
    margin: 0;
    font: inherit;
    color: currentColor;
    width: 16px;
    height: 16px;
    border-radius: 4px;
    transform: translateY(-0.075em);
  
    display: grid;
    place-content: center;
    cursor: pointer;
  }
  
  > input[type="checkbox"]::before {
    content: "";
    width: 8px;
    height: 8px;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    transform: scale(0);
    transform-origin: bottom left;
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em #ffffff;
    background-color: CanvasText;
  }
  
  > input[type="checkbox"]:checked::before {
    transform: scale(1);
  }
  
  
  
  > input[type="checkbox"]:disabled {
    color: #ffffff;
    cursor: not-allowed;
  }
  
}

.app-form-control-ghost {
  display: grid;
  grid-template-columns: 1em auto;
  gap: 0.5em;
  align-items: center;
  > input[type="checkbox"] {
    -webkit-appearance: none;
    appearance: none;
    background-color: #ffffff;
    margin: 0;
    font: inherit;
    color: currentColor;
    width: 16px;
    height: 16px;
    border-radius: 4px;
    transform: translateY(-0.075em);
    border: 2px solid #0097a9;
    display: grid;
    place-content: center;
    cursor: pointer;
  }
  
  > input[type="checkbox"]::before {
    content: "";
    width: 8px;
    height: 8px;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    transform: scale(0);
    transform-origin: bottom left;
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em #ffffff;
    background-color: CanvasText;
  }
  
  > input[type="checkbox"]:checked::before {
    transform: scale(1);
  }
  > input[type="checkbox"]:checked {
    background: #0097a9;
  }
  
  
  
  > input[type="checkbox"]:disabled {
    color: #ffffff;
    cursor: not-allowed;
  }
  
}

