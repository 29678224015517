@import '../variables';

.text-link {
  font-weight: 400;
  text-decoration: underline;
  text-decoration-color: $blue;
  color: $blue;
  cursor: pointer;
}

.app-renewal {
  padding-top: 0 !important;
  color: $zblack;
  font-weight: 400;
  &--content {
    width: 100%;
    max-width: 1180px;
    display: grid;
    grid-template-columns: 1fr 420px;
    margin: 0 auto;
    column-gap: 100px;
    padding: 24px;
    box-sizing: border-box;
    &--address {
      display: grid;
      grid-template-columns: 1fr;
      grid-auto-rows: auto;
      row-gap: 24px;
      margin-bottom: 24px;

      &--form {
        display: grid; 
        grid-template-columns: 1fr 1fr;
        gap: 2px 24px;
      }

      &--title {
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        color: $zblack;
      }
    }
    &--buttons {
      display: grid;
      grid-template-columns: 200px 200px;
      justify-content: space-between;
      margin: 36px 0 36px 0;
      column-gap: 12px;
    }
    &--contracts {
      &--description {
        font-weight: 400;
        font-size: 18px;
        color: $zblack;
      }
      &--agreements {
        display: grid;
        grid-auto-rows: auto;
        row-gap: 14px;
      }
    }
  }
}

.pdf-downloader-container {
  display: flex;
  padding: 24px;
  justify-content: center;
}

.pdf-downloader {
  padding: 24px 12px;
  box-shadow: 0px 4px 8px rgba(32, 32, 32, 0.24);
  border-radius: 6px;
  display: grid;
  grid-template-rows: max-content max-content;
  justify-items: center;
  row-gap: 12px;
  margin: 10px;
  width: 160px;
  user-select: none;
  cursor: pointer;
  > img {
    width: 56px;
    margin: 0 auto;
  }
  > span {
    font-size: 12px;
    font-weight: 400;
    text-align: center;
  }
}


/* Custom select */
select {
  appearance: none;
  background-color: transparent;
  border: none;
  padding: 0 1em 0 0;
  margin: 0;
  width: 100%;
  font-family: inherit;
  font-size: inherit;
  cursor: inherit;
  line-height: inherit;
  outline: none;
}
select::-ms-expand {
  display: none;
}
select, .custom-select:after {
  grid-area: select;
}
.custom-select {
  width: 80% !important;
  min-width: 15ch;
  max-width: 30ch;
  border: 2px solid var(--select-border);
  padding: 0.25em 0.5em;
  cursor: pointer;
  background-color: #fff;
  background-image: linear-gradient(to top, #f9f9f9, #fff 33%);
  display: grid;
  grid-template-areas: "select";
  align-items: center;
  position: relative;
  text-align: center;
  text-align-last: center;
}
.custom-select::after {
  content: "";
  width: 0.8em;
  height: 0.5em;
  background-color: var(--select-arrow);
  clip-path: polygon(100% 0%, 0 0%, 50% 100%);
  justify-self: end;
}








.toke-validation {
  display: grid;
  grid-auto-rows: auto;
  row-gap: 12px;
  justify-items: center;
  color: $zblack;
  > input {
    font-family: $font !important;
    color: $zblack !important;
    margin: 24px auto !important;
    font-size: 36px !important;
    background: repeating-linear-gradient(90deg,$zblack,$zblack 1ch,#0000 0,#0000 1.75ch) 0 100%/10.5ch 2px no-repeat !important;
    
  }
  &--title {
    color: $red;
    margin: 24px auto;
    font-weight: 500;
  }
  &--description {
    color: $zblack;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    max-width: 450px;
  }
  &--actions {
    width: max-content;
    display: grid;
    grid-auto-rows: auto;
    row-gap: 18px;
    > button {
      width: 100%;
    }
    &--link {
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: $blue;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}



.app-renewal-confirm {
  font-weight: 400;
  color: $zblack;
  padding: 24px 36px;
  box-sizing: border-box;
  max-width: 1240px;
  margin: 0 auto;
  &--header {
    display: grid;
    grid-auto-rows: auto;
    row-gap: 12px;
    justify-items: center;
    &--title {
      font-size: 14px;
    }
    &--subTitle {
      font-size: 18px;
      color: $grey;
    }
    > img {
      width: 60px;
    }
    > * {
      text-align: center;
    }

  }
  &--summary {
    display: grid;
    grid-auto-rows: auto;
    row-gap: 18px;
  }

  &--product {
    display: grid;
    grid-template-columns: 90px 1fr 200px;
    column-gap: 12px;
    padding: 24px 0;
    border-bottom: 1px solid $light;
    &--picture {
      display: flex;
      justify-content: center;
      align-items: center;
      > img {
        width: 90px;
        padding-top: 6px;
      }
    }
    &--detail {
      display: grid;
      grid-template-rows: max-content max-content;
      row-gap: 12px;
      align-content: space-between;
      &--name {
        font-size: 18px;
        display: grid;
        grid-template-columns: max-content max-content;
        column-gap: 12px;
      }
      &--specs {
        display: grid;
        grid-template-columns: max-content max-content max-content;
        gap: 24px;
        font-size: 14px;
      }
    }
    &--price {
      display: flex;
      justify-content: space-between;
      &--qty {
        font-weight: 700;
        font-size: 24px;
      }
      &--label {
        > span {
          display: none;
        }
      }
    }
  }
  &--transaction {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    padding: 12px 0;
    gap: 8px;
    column-gap: 8px;
    row-gap: 4px;
    .left {
      font-weight: 500;
    }
    .right {
      text-align: right;
    }
  }
  &--data-summary {
    display: grid;
    grid-auto-rows: auto;
    row-gap: 12px;
    &--header {
      display: grid;
      grid-template-columns: 1fr max-content;
    }
    &--grid {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      column-gap: 12px;
    }
    &--print {
      color: $blue;
      display: grid;
      grid-template-columns: max-content max-content;
      align-items: center;
      font-weight: 400;
      column-gap: 12px;
      cursor: pointer;
    }
  }
  &--important-note {
    display: grid;
    grid-template-columns: max-content 1fr;
    font-size: 14px;
    row-gap: 14px;
    column-gap: 12px;
  }


}

.app--divider {
  width: 100%;
  height: 1px;
  background: $zGrey;
  margin: 18px 0;
}

.mobile-print {
  display: none;
}

.desktop-print {
  display: block;
}


@media (max-width: 700px) {

  .desktop-print {
    display: none;
  }

  .mobile-print {
    display: block;
    display: flex;
    justify-content: center;
    margin: 18px 0px 0 0;
  }

  .pdf-downloader-container {
    display: grid;
    grid-template-columns: max-content;
    grid-auto-rows: auto;
  }

  .app-renewal {

    &--content {
      grid-template-columns: 1fr;
      column-gap: 0;
      grid-template-areas: "cart-summary" "form";
      row-gap: 36px;
      padding: 12px;
      &--form {
        grid-area: form;
      }
      &--cart-summary {
        grid-area: cart-summary;
      }

      &--address {
        &--form {
          grid-template-columns: 1fr;
        }
      }
      &--buttons {
        grid-template-columns: 1fr;
        gap: 18px;
        position: fixed;
        bottom: 0;
        background: white;
        left: 0;
        right: 0;
        padding: 12px;
        z-index: 1999;
        margin: 0;
        box-sizing: border-box;
      }

    }
  }
  .app-renewal-confirm {
    padding: 18px 12px;
    &--header {
      &--title {
        font-size: 12px;
      }
    }
    &--summary {
      > h2 {
        font-size: 24px;
      }
    }
    &--product {
      grid-template-columns: 1fr;
      column-gap: 12px;
      padding: 24px 0;
      border-bottom: 1px solid $light;
      row-gap: 12px;
      &--picture {
        display: flex;
        justify-content: center;
        align-items: center;
        > img {
          width: 160px;
          padding-top: 6px;
        }
      }
      &--detail {
        display: grid;
        grid-template-rows: max-content max-content;
        row-gap: 12px;
        align-content: space-between;
        &--name {
          font-size: 18px;
          display: grid;
          grid-template-columns: max-content max-content;
          column-gap: 12px;
          justify-content: space-between;
        }
        &--specs {
          display: grid;
          grid-template-columns: max-content max-content;
          justify-content: space-between;
          gap: 12px;
          font-size: 14px;
        }
      }
      &--price {
        display: flex;
        justify-content: space-between;
        &--qty {
          font-weight: 700;
          font-size: 24px;
        }
        &--label {
          padding-top: 12px;
          > span {
            display: block;
          }
        }
      }
    }
    &--data-summary {
      display: grid;
      grid-auto-rows: auto;
      row-gap: 12px;
      &--grid {
        display: grid;
        grid-template-columns: 1fr;
        column-gap: 12px;
        row-gap: 18px;
      }
      &--header {
        > h2 {
          font-size: 24px;
          margin-bottom: 18px;
        }
      }
    }
    &--transaction {
      display: grid;
      grid-template-columns: 1fr 1fr;
      width: 100%;
      padding: 12px 0;
      gap: 8px;
      font-size: 14px;
      .left {
        font-weight: 500;
      }
      .right {
        text-align: right;
      }
    }
    &--important-note {
      display: grid;
      grid-template-columns: 1fr;
      font-size: 14px;
      row-gap: 14px;
      justify-items: center;
    }
  }


}

