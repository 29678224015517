@import '../variables';

.app-checkbox {
  display: flex;
  align-items: center; 
  margin-right: 6px;
  width: max-content;
  cursor: pointer;
  &--circle {
    width: 12px;
    height: 12px;
    border: 2px solid $gray;
    border-radius: 50%;
    display: flex; 
    justify-content: center; 
    align-items: center;
    margin-right: 6px;
    &--selected {
      width: 8px;
      height: 8px; 
      background: #0097a9;
      border-radius: 50%;
    }
  }
  &--label {
    color: $zblack;
    font-weight: 400;
  }
}